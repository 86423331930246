import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import PostTemplateDetails from '../components/PostTemplateDetails';

class PostTemplate extends React.Component {
	render() {
		const { title, subtitle } = this.props.data.site.siteMetadata;
		const post = this.props.data.markdownRemark;
		const { title: postTitle, description: postDescription } = post.frontmatter;
		const description = postDescription !== null ? postDescription : subtitle;

		return (
			<Layout>
				<div>
					<Helmet>
						<title>{`${postTitle} - ${title}`}</title>
						<meta name="description" content={description} />
					</Helmet>
					<PostTemplateDetails {...this.props} />
				</div>
			</Layout>
		);
	}
}

export default PostTemplate;

export const pageQuery = graphql`
	query PostBySlug($slug: String!) {
		site {
			siteMetadata {
				title
				subtitle
				copyright
				author {
					name
					twitter
				}
				disqusShortname
				url
			}
		}
		markdownRemark(fields: { slug: { eq: $slug } }) {
			id
			html
			fields {
				tagSlugs
				slug
			}
			frontmatter {
				title
				tags
				date
				description
			}
		}
	}
`;
